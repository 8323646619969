import { APP_ROUTES } from "./appRoutes";
import { companyBasedAppData } from "./companyBasedAppData";
import PERMISSIONS_OBJECT from "./Permissions.json";

export const ENV_CONFIG = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  customerMgmt_baseURL: process.env.REACT_APP_CUSTOMER_MGMT_API_ENDPOINT,
  transactionMgmt_baseURL: process.env.REACT_APP_TRANSACTION_MGMT_API_ENDPOINT,
  reportMgmt_baseURL: process.env.REACT_APP_REPORT_MGMT_API_ENDPOINT,
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
  notificationWebSocketUrl: process.env.REACT_APP_NOTIFICATIONS_WEB_SOCKET_URL,
  vmConnectionWebSocketUrl: process.env.REACT_APP_VM_CONNECTION_WEB_SOCKET_URL,
  bulkStockSampleFileUrl: process.env.REACT_APP_BULK_STOCK_SAMPLE_FILE,
  sentryApiKey: process.env.REACT_APP_SENTRY_KEY,
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
};

export const BROWSER_STORAGE = {
  ACCESS_TOKEN: "access_token",
  USERS_DETAILS: "user_details",
  USER_PERMISSIONS: "user_permissions",
  COOKIE_EXPIRY_TIME: 60 * 60 * 23,
  TOKEN_EXPIRY_TIME: null,
};

export const NAVBAR_BRAND_ROUTES_FUNCTION =()=> {
  const getUserDetails = () => localStorage.getItem(BROWSER_STORAGE.USERS_DETAILS);
  const getUserData = JSON.parse(getUserDetails())
    const newCompanyBasedData= companyBasedAppData[getUserData?.module_id] || {};
   
    const NAVBAR_BRAND_ROUTES={
  "Customer Dashboard": ["/dashboard"],
  "Touch Point": [
    "/touchpoint-list",
    "/sync-touchpoint",
    APP_ROUTES.TOUCH_POINT_VIEW,
    "/touchpoint-groups-list",
    "/add-touchpoint-group",
    APP_ROUTES.TOUCHPOINT_GROUP_VIEW,
  ],

  "Touchpoint List": [APP_ROUTES.TOUCH_POINT_VIEW, "/touchpoint-list"],
  "Touchpoint Groups": [
    "/touchpoint-groups-list",
    "/add-touchpoint-group",
    APP_ROUTES.TOUCHPOINT_GROUP_VIEW,
  ],
  Channels: ["/channel-list"],
  Dashboard: ["/dashboard"],
  "Role Management": ["/roles", "/role", "/add-role", "/edit-role"],
  "User Management": ["/users", "/user", "/add-user", "/edit-user"],
  [`${
    newCompanyBasedData?.CustomerManagement?.customer_list?.title ||
    "Customer List"
  }`]: ["/customer-list"],

  [`${
    newCompanyBasedData?.CustomerManagement?.module_name ||
    "Customer Management"
  }`]: [
    APP_ROUTES.CUSTOMER_VIEW,
    "/customer-list",
    "/add-customer",
    "/group-list",
    "/add-customer/:id",
    "/group-view/:id",
    "/add-group/:id",
    APP_ROUTES.WALLET_LIST,
    APP_ROUTES.WALLET_CREATE,
  ],

  Groups: ["/group-list"],

  Institution: [
    "/instituition-list",
    "/instituition-view/:id",
    "/add-instituition",
    "/add-instituition/:id",
  ],

  "Delivery Partner": [
    "/delivery-partners",
    "/add-delivery-partner",
    "/edit-delivery-partner",
    "/delivery-partner",
  ],

  [`${newCompanyBasedData?.TransactionMgmt?.module_name || "Transactions"}`]: [
    APP_ROUTES.TRANSACTION_LIST,
    APP_ROUTES.TRANSACTION_VIEW,
  ],

  "Supplier Management": [APP_ROUTES.SUPPLIER_LIST, APP_ROUTES.SUPPLIER_VIEW],
  "Purchase Order": [
    APP_ROUTES.PURCHASE_ORDER_LIST,
    APP_ROUTES.PURCHASE_ORDER_VIEW,
  ],
  Rules: [
    APP_ROUTES.RULE_CREATE,
    APP_ROUTES.RULE_LIST,
    APP_ROUTES.RULE_EDIT,
    APP_ROUTES.RULE_VIEW,
  ],

  Wallet: [
    APP_ROUTES.WALLET_LIST,
    APP_ROUTES.WALLET_CREATE,
    APP_ROUTES.WALLET_EDIT,
    APP_ROUTES.WALLET_VIEW,
  ],

  // Campaign: ["/campaign-list", "/add-promocode"],
  // "Campaign List": ["/campaign-list"],
  // Promocode: ["/add-promocode"],
}
return NAVBAR_BRAND_ROUTES
};

export const DEFAULT_ERROR_MESSAGE = "Error occurred, please try again!";
export const DEFAULT_SUCCESS_MESSAGE = "Action was successful!";
export const NEW_ITEMS_LIMIT = [20, 50, 100];

export const PERMISSIONS = PERMISSIONS_OBJECT;

export const PERMISSIONS_GROUP_KEYS = {
  USER_MANAGEMENT: "User Management",
  ROLE_MANAGEMENT: "Role Management",
  PRODUCT_CATALOG: "Product Catalog",
  PRODUCT_CATEGORY: "Product Category",
  PRODUCT_BRAND: "Product Brand",
  PRODUCT_UNIT: "Product Unit",
  PRODUCT_PACKAGING: "Product Packaging",
};

export const PERMISSIONS_GROUP = {
  [PERMISSIONS_GROUP_KEYS.USER_MANAGEMENT]: {
    [PERMISSIONS.PERM_ADD_USER]: false,
    [PERMISSIONS.PERM_UPDATE_USER]: false,
    [PERMISSIONS.PERM_VIEW_USER]: false,
    [PERMISSIONS.PERM_DELETE_USER]: false,
  },
  [PERMISSIONS_GROUP_KEYS.ROLE_MANAGEMENT]: {
    [PERMISSIONS.PERM_ADD_ROLE]: false,
    [PERMISSIONS.PERM_UPDATE_ROLE]: false,
    [PERMISSIONS.PERM_VIEW_ROLE]: false,
    [PERMISSIONS.PERM_DELETE_ROLE]: false,
  },
  [PERMISSIONS_GROUP_KEYS.PRODUCT_CATALOG]: {
    [PERMISSIONS.PERM_ADD_PRODUCT_CATALOG]: false,
    [PERMISSIONS.PERM_UPDATE_PRODUCT_CATALOG]: false,
    [PERMISSIONS.PERM_VIEW_PRODUCT_CATALOG]: false,
    [PERMISSIONS.PERM_DELETE_PRODUCT_CATALOG]: false,
  },
  [PERMISSIONS_GROUP_KEYS.PRODUCT_CATEGORY]: {
    [PERMISSIONS.PERM_ADD_PRODUCT_CATEGORY]: false,
    [PERMISSIONS.PERM_UPDATE_PRODUCT_CATEGORY]: false,
    [PERMISSIONS.PERM_VIEW_PRODUCT_CATEGORY]: false,
    [PERMISSIONS.PERM_DELETE_PRODUCT_CATEGORY]: false,
  },
  [PERMISSIONS_GROUP_KEYS.PRODUCT_BRAND]: {
    [PERMISSIONS.PERM_ADD_BRAND]: false,
    [PERMISSIONS.PERM_UPDATE_BRAND]: false,
    [PERMISSIONS.PERM_VIEW_BRAND]: false,
    [PERMISSIONS.PERM_DELETE_BRAND]: false,
  },
  [PERMISSIONS_GROUP_KEYS.PRODUCT_UNIT]: {
    [PERMISSIONS.PERM_ADD_PRODUCT_UNIT]: false,
    [PERMISSIONS.PERM_UPDATE_PRODUCT_UNIT]: false,
    [PERMISSIONS.PERM_VIEW_PRODUCT_UNIT]: false,
    [PERMISSIONS.PERM_DELETE_PRODUCT_UNIT]: false,
  },
  [PERMISSIONS_GROUP_KEYS.PRODUCT_PACKAGING]: {
    [PERMISSIONS.PERM_ADD_PACKAGING]: false,
    [PERMISSIONS.PERM_UPDATE_PACKAGING]: false,
    [PERMISSIONS.PERM_VIEW_PACKAGING]: false,
    [PERMISSIONS.PERM_DELETE_PACKAGING]: false,
  },
};

export const TABLES_REF = {
  ORDER_LIST_TABLE: [
    "order_id",
    "product_name",
    "amount",
    "time",
    "payment_status",
    "action",
  ],

  CUSTOMER_LIST_TABLE: [
    "customer_name",
    "mobile",
    "class_name",
    "section_name",
    "action",
  ],

  GROUP_LIST_TABLE: ["name", "action"],

  RULE_LIST_TABLE: [
    "name",
    "touch_point",
    "channel",
    "status",
    "group",
    "action",
  ],

  INSTITUTION_LIST_TABLE: [
    "name",
    "customer_grps",
    "touchpoint_grps",
    "action",
  ],

  CHANNEL_LIST_TABLE: ["channel_name", "sync_touchpoint"],

  TOUCHPOINT_LIST_TABLE: [
    "touchpoint_name",
    "channel",
    "status",
    "last_updated",
    "actions",
  ],
  TOUCHPOINT_GROUP_LIST_TABLE: ["name", "actions"],

  TRANSACTION_LIST_TABLE: [
    "order_id",
    "date",
    "channel",
    "touchpoint",
    "customer",
    "payment_type",
    "amount",
    "status",
    "refund",
  ],

  WALLET_LIST_TABLE: ["wallet_id", "name", "amount", "action"],

  ORDER_REFUND_LIST_TABLE: [
    "name",
    "quantity",
    "coil",
    "refund_amount",
    "status",
    "created_at",
    "updated_at",
  ],

  CART_ORDER_LIST_TABLE: ["name", "qty", "mrp", "tax","coil", "status", "amount"],

  CUSTOMER_WALLET_TRANSACTION_LIST_TABLE: [
    "id",
    "wallet_id",
    "record_type",
    "previous_balance",
    "processed_amt",
    "total_balance",
  ],

  CREATE_RULE_TABLE: [
    "sno",
    "name",
    "time",
    "limit",
    "action",
  ],

  VIEW_RULE_TABLE: [
    "sno",
    "name",
    "time",
    "limit",
  ],
};

// Shipping status Keys is used for matching Shipping status values
export const SHIPPING_STATUS_KEYS = {
  ORDER_CREATED: "ORDER_CREATED",
  IN_PROGRESS: "IN_PROGRESS",
  DISPATCHED: "DISPATCHED",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
  DELIVERED_DP: "DELIVERED_DP",
  RECEIVED_SM: "RECEIVED_SM",
  IN_TRANSIT: "IN_TRANSIT",
};

export const SHIPPING_ORDER_STATUS = {
  [SHIPPING_STATUS_KEYS.ORDER_CREATED]: {
    displayName: "Order Created",
    color: "#3987FD",
    shippingIndex: 1,
  },
  [SHIPPING_STATUS_KEYS.IN_PROGRESS]: {
    displayName: "In Progress",
    color: "#FBC658",
    shippingIndex: 2,
  },
  [SHIPPING_STATUS_KEYS.DISPATCHED]: {
    displayName: "Dispatched",
    color: "#4B6E86",
    shippingIndex: 3,
  },
  [SHIPPING_STATUS_KEYS.DELIVERED]: {
    displayName: "Received",
    color: "#2C8300",
    shippingIndex: 5,
    forNotKey: SHIPPING_STATUS_KEYS.RECEIVED_SM,
  },
  [SHIPPING_STATUS_KEYS.CANCELLED]: {
    displayName: "Cancelled",
    color: "#FF2E43",
    shippingIndex: null,
  },
  [SHIPPING_STATUS_KEYS.DELIVERED_DP]: {
    displayName: "Delivered",
    color: "#f95a3f",
    shippingIndex: 4,
    forNotKey: SHIPPING_STATUS_KEYS.IN_TRANSIT,
  },
  [SHIPPING_STATUS_KEYS.IN_TRANSIT]: {
    displayName: "In Transit",
    color: "#f95a3f",
    shippingIndex: 4,
    forKeyOnly: SHIPPING_STATUS_KEYS.IN_TRANSIT,
  },
  [SHIPPING_STATUS_KEYS.RECEIVED_SM]: {
    displayName: "Received",
    color: "#2C8300",
    shippingIndex: 5,
    forKeyOnly: SHIPPING_STATUS_KEYS.RECEIVED_SM,
  },
};

export const REFILL_MANAGEMENT_STATUS = {
  CREATED: {
    displayName: "Created",
    color: "#3987FD",
  },
  IN_TRANSIT: {
    displayName: "In Transit",
    color: "#FBC658",
  },
  EXECUTED: {
    displayName: "Executed",
    color: "#2C8300",
  },
};
export const ITEMS_LIMIT = [10, 20, 25];
export const ASC = "asc";
export const DESC = "desc";

export const NOTIFICATION_TIME_PERIOD_ARR = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Today",
    value: "day",
  },
  {
    name: "Week",
    value: "week",
  },
  {
    name: "Month",
    value: "month",
  },
  {
    name: "Year",
    value: "year",
  },
];
// real time notification types to check notification
export const NOTIFICATION_TYPES_KEY = {
  REFILL_EXECUTE: "REFILL_EXECUTE",
  VM_STATUS: "VM_STATUS",
};
export const NOTIFICATION_TYPES = {
  REFILL_EXECUTE: "Refill Executed",
  ABOUT_TO_RUNOUT: "About to Runout",
  OUT_OF_STOCK: "Out of Stock",
  PRODUCT_REQUEST: "Product Requested",
  PRODUCT_EXPIRED: "Product Expired",
  VEND_FAILURE: "Vend Failure",
  PARTIAL_VEND_FAILURE: "Partial Vend Failure",
  PAYMENT_TIMEDOUT: "Payment Timedout",
  VM_STATUS: "Vending Machine Status",
  MANUAL_REFUND: "Manual Refund",
  AUTO_REFUND: "Auto Refund",
  CANCELLED_BY_CONSUMER: "Cancelled by Consumer",
  VEND_SUCCESS: "Vend Success",
  DISCOUNT_EXPIRED: "Discount Expired",
  TRANSACTION_REPORT_RESPONSE: "Transaction Reports",
};

export const SALES_ANALYTICS_PRODUCTS_ARR = {
  product: "Product",
  product_category: "Product Category",
  product_brand: "Product Brand",
  product_unit: "Product Unit",
};

export const SALES_ANALYTICS_LOCATIONS_ARR = {
  franchise: "Franchise",
  state: "State",
  city: "City",
  zone: "Zone",
  vending_machine: "Vending Machine",
};

export const SALES_ANALYTICS_RANGE_ARR = [
  {
    value: "top_10",
    name: "Top 10",
  },
  {
    value: "top_20_percent",
    name: "Top 20%",
  },
  {
    value: "bottom_10",
    name: "Bottom 10",
  },
  {
    value: "bottom_20_percent",
    name: "Bottom 20%",
  },
  {
    value: "all",
    name: "All",
  },
  {
    value: "manual",
    name: "Select Manually",
  },
];

//Enumerations
export const ENUMS = {
  COUPON_TYPE: { FLAT: "FLAT", PERCENTAGE: "PERCENTAGE" },
  ALL_DROPDOWN_OPTION: { ID: "ALL", TEXT: "All" },
  DISCOUNT_TYPE: {
    FLAT: "FLAT",
    PERCENTAGE: "PERCENTAGE",
    BUY_X_FOR_Y: "COMBO_TYPE_II",
    BUY_X_GET_Y: "COMBO_TYPE_I",
  },
  COMPANY_TYPE: {
    WITH_FRANCHISE: "WITH_FRANCHISE",
    WITHOUT_FRANCHISE: "WITHOUT_FRANCHISE",
  },
  FRANCHISE_TYPE: {
    DEPENDENT: {
      type: "DEPENDENT",
      title: "Dependent Franchise",
      description: "This franchise won’t have any stores",
    },
    INDEPENDENT: {
      type: "INDEPENDENT",
      title: "Independent Franchise",
      description: "This franchise will have stores",
    },
  },
};

//Status codes for api.
export const API_STATUS_CODES = {
  LICENSE_EXPIRED: 402,
};

// Variables default values.
export const DEFAULT_VALUES = {
  STAR_RATING: 5,
  DOUBLE_DASH: "--",
  NOT_APPLICABLE: "N/A",
  PERCENTAGE: "%",
  DEPENDENT_FRANCHISE_STORE_MSG: "No store required for this franchise",
};

//User roles
export const USER_ROLES = {
  COMPANY_ADMIN: "ROLE_COMPANY_ADMIN",
  COMPANY_PARTNER: "ROLE_COMPANY_PARTNER",
  FRANCHISE_ADMIN: "ROLE_FRANCHISE_ADMIN",
};

//Vending machine boards name
export const VENDIFY_BOARD_NAMES = {
  PINE64: "Vendi-Pro Board (with Display)",
  ESP32: "Vendi-Mini Board (without Display)",
};

//prevent any function default behaviour.
export const preventDefaultBehaviour = (e) => {
  e.preventDefault();
};

//Manual error messages for the app.
export const ERROR_MESSAGES = {
  COUPON_MAX_OFF: `Maximum off should be less than minimum cart value & atleast 1.`,
  COUPON_MIN_CART: "Minimum cart value should be at least 1.",
  COUPON_FLAT_OFF:
    "Flat off should be atleast 1 and less than minimum cart value.",
  COUPON_PERCENT_OFF: "Percentage off should be atleast 1 and less than 100.",
  COUPON_MAX_REDEMPTION: "Maximum redemption should be atleast 1.",
  COUPON_DISCOUNT_LESS_THAN_ONE: "Minimum discount price should be 1.",
  DISCOUNT_FLAT_OFF: "Flat off should be less than price.",
  DISCOUNT_MAX_BUY_QUANTITY: "Max buy quantity should be atleast 1.",
  DISCOUNT_PERCENT_OFF: "Percentage off should be atleast 1 and less than 100.",
  DISCOUNT_BUY_QUANTITY: "Buy quantity should be atleast 1.",
  DISCOUNT_OFFERED_PRICE: "Offered price should be less than combo price.",
  DISCOUNT_FREE_QUANTITY: "Free quantity should be less than buy quantity.",
};

//Keyboard codes
export const KEY_CODES = {
  SPACE_KEY: "Space",
  DOT_KEY: "Period",
  ENTER_KEY: "Enter",
  NUMPAD_ENTER_KEY: "NumpadEnter",
  NEGATIVE_KEY: "Minus",
  NUMPAD_DOT_KEY: "NumpadDecimal",
};

export const REG_EXPS = {
  ALPHABETS_AND_NUMBERS: /^[a-zA-Z0-9]*$/,
  ALPHABETS_ONLY: /^[a-zA-Z]*$/,
  ALPHABETS_AND_SPACE: /^[a-zA-Z][a-zA-Z ]*$/,
  NUMBERS_ONLY: /^[0-9]*$/,
  EMAIL: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
  ALPHABETS_NUMBERS_AND_SPECIAL_CHARACTERS:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_-]).{8,}$/,
  FOUR_DIGITS_ONLY: /^\d{0,4}$/,
};

//Discount types rule for validation
export const DISCOUNT_TYPE_PRODUCTS_RULE = {
  [ENUMS.DISCOUNT_TYPE.FLAT]: {
    isArrayJson: true,
    get_value: {
      required: true,
      minVal: 1,
      number: true,
      errorMessage: ERROR_MESSAGES.DISCOUNT_FLAT_OFF,
    },
    max_buy_quantity: {
      minVal: 1,
      number: true,
      errorMessage: ERROR_MESSAGES.DISCOUNT_MAX_BUY_QUANTITY,
    },
    separator: "id",
    hasDynamicRules: true,
    dynamicRules: {},
    required: true,
  },
  [ENUMS.DISCOUNT_TYPE.PERCENTAGE]: {
    isArrayJson: true,
    get_value: {
      required: true,
      minVal: 1,
      maxVal: 99,
      number: true,
      errorMessage: ERROR_MESSAGES.DISCOUNT_PERCENT_OFF,
    },
    max_buy_quantity: {
      minVal: 1,
      number: true,
      errorMessage: ERROR_MESSAGES.DISCOUNT_MAX_BUY_QUANTITY,
    },
    separator: "id",
    required: true,
  },
  [ENUMS.DISCOUNT_TYPE.BUY_X_FOR_Y]: {
    isArrayJson: true,
    buy_quantity: {
      required: true,
      minVal: 1,
      number: true,
      errorMessage: ERROR_MESSAGES.DISCOUNT_BUY_QUANTITY,
    },
    get_value: {
      required: true,
      minVal: 1,
      number: true,
      errorMessage: ERROR_MESSAGES.DISCOUNT_OFFERED_PRICE,
    },
    separator: "id",
    hasDynamicRules: true,
    dynamicRules: {},
    required: true,
  },
  [ENUMS.DISCOUNT_TYPE.BUY_X_GET_Y]: {
    isArrayJson: true,
    buy_quantity: {
      required: true,
      minVal: 1,
      number: true,
      errorMessage: ERROR_MESSAGES.DISCOUNT_BUY_QUANTITY,
    },
    get_value: {
      required: true,
      minVal: 1,
      number: true,
      errorMessage: ERROR_MESSAGES.DISCOUNT_FREE_QUANTITY,
    },
    separator: "id",
    hasDynamicRules: true,
    dynamicRules: {},
    required: true,
  },
};

//Whenever we want a function to do nothing, this helps.
export const DUMMY_FUNCTION = () => {};

export const CURRENCIES = {
  RUPEES: "₹",
};

export const SYMBOLS = {
  PERCENTAGE: "%",
};

//Statuses for purchase orders.
export const PURCHASE_ORDER_STATUSES = {
  CREATED: { key: "CREATED", displayText: "Created", displayColor: "#3987FD" },
  PARTIALLY_RECEIVED: {
    key: "PARTIALLY_RECEIVED",
    displayText: "Partially Received",
    displayColor: "#FFC41E",
  },
  RECEIVED: {
    key: "RECEIVED",
    displayText: "Received",
    displayColor: "#4CB04E",
  },
  CANCELLED: {
    key: "CANCELLED",
    displayText: "Cancelled",
    displayColor: "#FF2E43",
  },
};

//List of popular browsers with how to detect them using regular expression.
export const BROWSER_TYPES = {
  CHROME: { name: "CHROME", detectRegExp: /chrome|chromium|crios/i },
  FIREFOX: { name: "FIREFOX", detectRegExp: /firefox|fxios/i },
  SAFARI: { name: "SAFARI", detectRegExp: /safari/i },
  OPERA: { name: "OPERA", detectRegExp: /opr\//i },
  EDGE: { name: "EDGE", detectRegExp: /edg/i },
};

//Returns user browser name.
export function detectBrowserType() {
  let userAgent = navigator.userAgent;

  return Object.values(BROWSER_TYPES).filter((browser) =>
    userAgent.match(browser.detectRegExp)
  )[0];
}

export const PAYMENT_ORIGINS = {
  1: { displayName: "Mobile Application" },
  2: { displayName: "Mobile Browser" },
  3: { displayName: "Touch screen display" },
};

export const BULK_REFILL_STATUSES = {
  1: { displayName: "Unexecuted", colorCode: "#ff0018" },
  2: { displayName: "Partially Executed", colorCode: "#f9c83f" },
  3: { displayName: "Fully Executed", colorCode: "#4cb04e" },
  6: { displayName: "Not generated", colorCode: "#ff0018" },
};

export const REFILL_STATUSES_IN_BULK_REFILL = {
  1: { displayName: "Pending", colorCode: "#f9c83f" },
  2: { displayName: "Pending", colorCode: "#f9c83f" },
  3: { displayName: "Executed", colorCode: "#4cb04e" },
  4: { displayName: "Dispatched", colorCode: "#f9c83f" },
  6: { displayName: "Not generated", colorCode: "#ff0018" },
};

export const TRANSACTIONS_DATE_FILTER = {
  TODAY: "today",
  YESTERDAY: "yesterday",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
  CUSTOM: "custom",
  ALL: "all",
};

export const LOCATION_COORDS = {
  DELHI: { latitude: 28.6448, longitude: 77.216721 },
};

export const DROP_SENSOR_STATUSES = {
  ACTIVE: { key: 1, display: "Active" },
  INACTIVE: { key: 2, display: "Inactive" },
  FAILED: { key: 3, display: "Failed" },
};
