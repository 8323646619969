export const companyBasedAppData = {
  "module-uuid-1": {
    CustomerManagement: {
      module_name: "Employee Management",
      customer_list: {
        title: "Employee List",
        add_customer: "Add Employee",
        pagination_title: "Employees",
      },

      customer_overview: {
        title: "Employee Overview",
      },
      add_customer_page: {
        title: "Add New Employee",
      },
    },

    TransactionMgmt: {
      module_name: "Dispensed Products",
      transaction_list: {
        title: "Dispensed Products List",
        pagination_title: "Dispensed Products",
      },
    },
  },
};
