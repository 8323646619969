import validator from "validator";

export default function validation(data, rules) {
  let errors = {};
  Object.keys(data).forEach((field) => {
    if (rules.hasOwnProperty(field)) {
      //If requested rules are for array of json
      if (rules[field].isArrayJson) {
        if (rules[field].required && data[field].length === 0) {
          return (errors = { ...errors, [field]: ["Value required"] });
        }
        //Loop through each json and set their rules.
        data[field].map((ele) => {
          //Rules for this this particular ele.
          let localRules = {};

          Object.keys(rules[field]).forEach((key) => {
            //Make rules for every key in rules except isArray & separator.
            if (
              ![
                "isArrayJson",
                "separator",
                "dynamicRules",
                "hasDynamicRules",
              ].includes(key)
            ) {
              let currentKey = key + "_" + ele[rules[field].separator];
              if (
                rules[field].hasDynamicRules &&
                currentKey in rules[field]["dynamicRules"]
              ) {
                localRules[currentKey] = {
                  ...rules[field]["dynamicRules"][currentKey],
                };
              }
              localRules[currentKey] = {
                ...localRules[currentKey],
                ...rules[field][key],
              };
            }
          });
          //Using recursion, this will return error for this particular element.
          let localError = validation(ele, localRules);
          //concatenate the current element errors with main errors.
          errors = { ...errors, ...localError };
        });
      }
      let fielderrors = [];
      let val =
        typeof data[field] === "string" || data[field] === null
          ? data[field]
          : JSON.stringify(data[field]);
          
      if (val !== null && val !== undefined) {
        if (
          rules[field].required &&
          (validator.isEmpty(val) || val === "{}" || val === "[]")
        ) {
          rules[field]?.customMessage
          ? fielderrors.push(rules[field]?.customMessage)
          : fielderrors.push(`Value required`);
        }
        if (!validator.isEmpty(val)) {
          if (
            rules[field].isMobile &&
            parseInt(val).toString().length !== rules[field].exactlength
          ) {
            fielderrors.push(`Enter valid mobile number`);
          }
          if (
            rules[field].minLength &&
            typeof val !== "number" &&
            val.length < rules[field].minLength
          ) {
            fielderrors.push(
              `Enter at least ${rules[field].minLength}` + " characters"
            );
          }
          if (
            rules[field].maxLength &&
            typeof val !== "number" &&
            val.length > rules[field].maxLength
          ) {
            fielderrors.push(
              `Enter at most ${rules[field].maxLength}` + " characters"
            );
          }
          if (
            rules[field].exactLength &&
            typeof val !== "number" &&
            val.length != rules[field].exactLength
          ) {
            fielderrors.push(
              `Characters must be ${rules[field].exactLength}` + " in length"
            );
          }
          if (rules[field].number && !validator.isNumeric(val)) {
            fielderrors.push("Enter only numbers");
          }
          if (
            rules[field].number &&
            validator.isNumeric(val) &&
            rules[field].minVal !== undefined &&
            parseInt(val) < rules[field].minVal
          ) {
            fielderrors.push(
              rules[field].errorMessage ||
                `Enter number greater than or equal to ${rules[field].minVal}`
            );
          }
          if (
            rules[field].number &&
            validator.isNumeric(val) &&
            rules[field].maxVal !== undefined &&
            parseInt(val) > rules[field].maxVal
          ) {
            fielderrors.push(
              rules[field].errorMessage ||
                `Enter number smaller than or equal to ${rules[field].maxVal}`
            );
          }
          if (rules[field].alpha && !validator.isAlpha(val)) {
            fielderrors.push("Enter only letters");
          }
          if (rules[field].email && !validator.isEmail(val)) {
            fielderrors.push("Enter a valid email address");
          }
          if (
            rules[field].pattern &&
            !validator.matches(val, rules[field].pattern)
          ) {
            fielderrors.push("Invalid File");
          }
          if (rules[field].url && !validator.isURL(val)) {
            fielderrors.push("Enter a valid url");
          }
          //For decimal numbers validation
          if (
            rules[field].decimalNumber &&
            rules[field].startDecimalVal !== undefined &&
            parseFloat(val) <= rules[field].startDecimalVal
          ) {
            fielderrors.push(
              rules[field].errorMessage ||
                `Enter number greater than ${rules[field].startDecimalVal}`
            );
          }
          if (
            rules[field].decimalNumber &&
            rules[field].endDecimalVal !== undefined &&
            parseFloat(val) >= rules[field].endDecimalVal
          ) {
            fielderrors.push(
              rules[field].errorMessage ||
                `Enter number less than ${rules[field].endDecimalVal}`
            );
          }
        }
      } else if (val === null && rules[field].required) {
        rules[field]?.customMessage
          ? fielderrors.push(rules[field]?.customMessage)
          : fielderrors.push(`Value required`);
      }
      if (fielderrors.length > 0) {
        errors[field] = fielderrors;
      }
    }
  });
  return errors;
}
